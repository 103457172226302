import { CONFIG } from './config'

export const WORDS = [
  'baadaw',
  'baʔas',
  'baʔax',
  'bilaš',
  'biʕaat',
  'buwač',
  'č̓aap̓i',
  'caapid',
  'c̓aawił',
  'č̓ałač̓',
  'c̓aʔak',
  'čičiʔ',
  'čiiwad',
  'c̓iwaap',
  'c̓iyip',
  'č̓iʔic',
  'cuwit',
  'duč̓ak',
  'dučʔi',
  'haadaq',
  'ḥičiid',
  'hiidap',
  'hiʔiiʔb',
  'kaciid',
  'k̓atuk',
  'keebič',
  'k̓uc̓up',
  'k̓uuc̓ay̓',
  'k̓uudis',
  'kʷaadis',
  'kʷałuk',
  'k̓ʷuuxʷaaš',
  'laayis',
  'ƛ̓abas',
  'lapas',
  'letas',
  'ƛ̓ipuus',
  'lisool',
  'ƛ̓ix̣iib',
  'ƛ̓učaq',
  'lupča',
  'ƛ̓uudup',
  'monkii',
  'paawic',
  'piilaaq',
  'p̓ip̓iʔ',
  'qaawic',
  'qaliʔ',
  'qaqup',
  'qaway',
  'qičiid',
  'qiiwax̣',
  'qit̓ap',
  'qʷaač̓id',
  'q̓ʷiilaax̣',
  'saasin',
  'sabaas',
  'sac̓up',
  'šičaak',
  'suč̓as',
  'šuukʷaʔ',
  'šuyuuł',
  'tabaax̣',
  't̓abuuk̓ʷ',
  't̓ak̓ʷas',
  'tibuut',
  't̓ickaa',
  'tiičaq',
  'tiłuup',
  'tipiix̣',
  'tiqiiw',
  't̓ucup',
  'tup̓ał',
  'tuxuł',
  'waaqaat',
  'waaxdii',
  'weeʕit',
  'wiiqsii',
  'x̣iʔid',
  'x̣ubis',
  'yačaʔ',
  'ʕat̓aw',
  'ʕaʕukʷ',
  'ʕeelis',
  'ʕibiiq',
  'ʕuqʷac',
  'ʔusiit',
]

if (CONFIG.normalization) {
  WORDS.forEach((val, i) => (WORDS[i] = val.normalize(CONFIG.normalization)))
}

function shuffle(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
}

if (CONFIG.shuffle) {
  shuffle(WORDS)
}
